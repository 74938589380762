import randomFromArray from "../utils/RandomArray";

export default class MatchInfo {
    date = "";              // 比赛日期
    location = "";          // 比赛地点
    type = "";              // 比赛类型
    competition = "";       // 比赛项目
    round = "";             // 比赛轮次
    players = [[""], [""]]; // 参赛球员，players[0]/players[1]为A/B队球员名的数组 (单打为1人，双打为2人)
    has_data = false;       // 是否已经有数据，only read from backend
    has_video = false;      // 是否已经有视频，only read from backend
    has_purevideo = false;

    static TYPE = [         // 所有的比赛类型
        {key: "WorldTour", label: '公开赛'},
        {key: "WorldChampion", label: '世乒赛'},
        {key: "WorldCup", label: '世界杯'},
        {key: "Olympic", label: '奥运会'},
        {key: "ChineseSuper", label: '乒超联赛'},
        {key: "AsianGame", label: '亚运会'},
        {key: "OlympicChoose", label: '奥运选拔赛'},
        {key: "AsianChampion", label: '亚锦赛'},
        {key: "AsianCup", label: '亚洲杯'},
        {key: "InGroup", label: '队内赛'},
        {key: "AntiEuroAsia", label: '欧亚对抗赛'},
        {key: "OtherType", label: '其他'},
        {key: "WorldFinal", label: "国际乒联总决赛"},
        {key:"WTTNormal",label:"WTT常规挑战赛"},
        {key:"WTTStar",label:"WTT球星挑战赛"},
        {key:"WTTWorldCupFinal",label:"WTT世界杯总决赛"},
        {key:"WTTFeeder",label:"WTT支线赛"},
        {key:"WTTChampions",label:"WTT冠军赛"},
        {key:"WTTSmash",label:"WTT大满贯赛"},
        {key:"WTTYouthNormal",label:"WTT青少年常规挑战赛"},
        {key:"WTTYouthStar",label:"WTT青少年球星挑战赛"},
        {key:"Top32",label:"32强赛"},
        {key:"WorldYouthChampion",label:"世青赛"},
        {key:"AsianYouthChampion",label:"亚青赛"},
        {key:"WTTFinalMen",label:"WTT男子总决赛"},
        {key:"WTTFinalWomen",label:"WTT女子总决赛"},
        {key:"Tryouts",label:"选拔赛"},
        {key:"Passes",label:"直通赛"},
        {key:"WorldTeamChampion",label:"世乒赛团体赛"},
    ];
    static COMPETITION = [  // 所有的比赛项目
        {key: "MenT", label: '男团'},
        {key: "MenS", label: '男单'},
        {key: "MenD", label: '男双'},
        {key: "WomenT", label: '女团'},
        {key: "WomenS", label: '女单'},
        {key: "WomenD", label: '女双'},
        {key: "MixedD", label: '混双'},

        {key: "MenTS", label: '男团单打'},
        {key: "MenTD", label: '男团双打'},
        {key: "WomenTS", label: '女团单打'},
        {key: "WomenTD", label: '女团双打'},
        {key: "MixedS", label: '混单'},

    ];
    static ROUND = [        // 所有的比赛轮次
        {key: "Final", label: '决赛'},
        {key: "SemiFinal", label: '半决赛'},
        {key: "QuarterFinal", label: '四分之一决赛'},
        {key: "ThirdPlaceFinal", label: '三四名决赛'},
        {key: "Round16", label: '八分之一决赛'},
        {key: "Round32", label: '十六分之一决赛'},
        {key: "Groups1", label: '小组赛第一轮'},
        {key: "Groups2", label: '小组赛第二轮'},
        {key: "Groups3", label: '小组赛第三轮'},
        {key: "R1", label: '第一轮'},
        {key: "R2", label: '第二轮'},
        {key: "R3", label: '第三轮'},
        {key: "OtherRound", label: '其他'},

        {key: "Round64", label: '三十二分之一决赛'},
        {key: "Round128", label: '六十四分之一决赛'},
        {key: "Formal1", label: '正赛第一轮'},
        {key: "Formal2", label: '正赛第二轮'},
        {key: "Formal3", label: '正赛第三轮'},
        {key: "Qualify1", label: '资格赛第一轮'},
        {key: "Qualify2", label: '资格赛第二轮'},
        {key: "Qualify3", label: '资格赛第三轮'},
        {key: "Qualify4", label: '资格赛第四轮'},

    ];

    constructor(matchJson) {
        this.date = matchJson.date;
        this.location = matchJson.location;
        this.type = matchJson.type;
        this.competition = matchJson.competition;
        this.round = matchJson.round;
        this.players = matchJson.players;
        this.has_data = matchJson.has_data;
        this.has_video = matchJson.has_video;
        this.has_purevideo = matchJson.has_purevideo;
    }

    copy() {
        return new MatchInfo(this);
    }

    genName() {
        const typeName = MatchInfo.TYPE.find(t => t.key === this.type).label;
        const competitionName = MatchInfo.COMPETITION.find(t => t.key === this.competition).label;
        const roundName = MatchInfo.ROUND.find(t => t.key === this.round).label;

        return `${this.date} ${this.location}${typeName} ${competitionName}${roundName} ${this.players[0].join()}vs${this.players[1].join()}`
    }

    str() {
        return JSON.stringify(this.json())
    }

    json() {
        return {
            date: this.date,
            location: this.location,
            type: this.type,
            competition: this.competition,
            round: this.round,
            players: this.players,
        }
    }

    static random() {
        const dates = ["20210413", "20200229", "20170831", "20200831", "20180710", "20190421"];
        const locations = ["中国", "韩国", "横滨", "多哈", "伦敦", "奥地利"];
        const playerNames = ["马龙", "许昕", "王楚钦", "刘诗雯", "丁宁", "伊藤美诚", "平野美宇", "波尔", "张本智和"];

        const date = randomFromArray(dates),
          location = randomFromArray(locations),
          type = randomFromArray(MatchInfo.TYPE).key,
          competition = randomFromArray(MatchInfo.COMPETITION).key,
          round = randomFromArray(MatchInfo.ROUND).key;

        const playerNum = (competition.endsWith("D")) ? 2 : 1;
        const players = [
            [...new Array(playerNum)].map(_ => randomFromArray(playerNames, false)),
            [...new Array(playerNum)].map(_ => randomFromArray(playerNames, false)),
        ];

        const has_data = randomFromArray([true, false]);
        const has_video = randomFromArray([true, false]);
        const has_purevideo = randomFromArray([true, false]);

        return new MatchInfo({
            date,
            location,
            type,
            competition,
            round,
            players,
            has_data,
            has_video,
            has_purevideo
        })
    }

    static fromFile(projFile) {
        const fr = new FileReader();
        return new Promise((resolve, reject) => {
            fr.onload = ev => {
                const proj = JSON.parse(ev.target.result);
                const date = projFile.name.substr(0, 8);
                const {location, type, competition, round} = proj.data.match;
                const players = proj.data.player.map(side => side.map(player => player.name));

                const findType = this.TYPE.find((val, idx) => type === val.key || type === idx) ||
                  {key: "OtherType"};
                const findCompetition = this.COMPETITION.find((val, idx) => competition === val.key || competition === idx) ||
                  {key: "MenS"};
                const findRound = this.ROUND.find((val, idx) => round === val.key || round === idx) ||
                  {key: "OtherRound"};

                const match = new MatchInfo({
                    date,
                    location,
                    players,
                    type: findType.key,
                    competition: findCompetition.key,
                    round: findRound.key,
                })
                resolve(match);
            }
            fr.onerror = ev => {
                reject(ev);
            }
            fr.readAsText(projFile);
        })
    }

    static fromName(fileName, playersData) {
        if (fileName.endsWith('.json')) fileName = fileName.substr(0, fileName.length - 5);
        if (fileName.endsWith('.mp4')) fileName = fileName.substr(0, fileName.length - 4);
        if (fileName.endsWith('-video')) fileName = fileName.substr(0, fileName.length - 6);
        if (fileName.endsWith('-collect_project')) fileName = fileName.substr(0, fileName.length - 16);
        if (fileName.endsWith('-data')) fileName = fileName.substr(0, fileName.length - 5);
        const [date, loc_type, comp_round, players_vs] = fileName.split(' ')

        let location = '未知';
        let type = 'OtherType';
        for (const {key, label: value} of this.TYPE) {
            const idx = loc_type.indexOf(value)
            if (idx !== -1) {
                location = loc_type.substr(0, idx);
                type = key;
                break;
            }
        }

        let competition = 'MenT'
        for (const {key, label: value} of this.COMPETITION) {
            if (comp_round.includes(value)) {
                competition = key;
                break;
            }
        }

        let round = 'OtherRound'
        for (const {key, label: value} of this.ROUND) {
            if (comp_round.includes(value)) {
                round = key;
                // no break to avoid only finding 决赛 in some 半决赛 matches
            }
        }

        const players = competition.endsWith('双') ? [['', ''], ['', '']] : [[''], ['']];
        const [side0, side1] = players_vs.split('vs');
        for (const player of playersData) {
            const player_name = player['name'];
            if (side0.startsWith(player_name)) players[0][0] = player_name
            else if (side0.endsWith(player_name)) {
                if (players[0].length === 1) players[0].push('')
                players[0][1] = player_name
            }

            if (side1.startsWith(player_name)) players[1][0] = player_name
            else if (side1.endsWith(player_name)) {
                if (players[1].length === 1) players[1].push('')
                players[1][1] = player_name
            }
        }
        return new MatchInfo({date, location, type, competition, round, players});
    }
}
